import { each, get, map } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayProfileColor } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';
import familyService from '../../../services/familyService';
import httpService from '../../../services/httpService';
import FamilyAccountSettingsForm from '../../Forms/FamilyAccountSettingsForm';
import { ProfileColorPicker } from '../../shared';

const AvatarDisplayComponent = (props) => {
  return (
    <div className="avatar">
      <div className="avatar-wrapper">
        {!!props.value && <img src={props.value} />}
        {!props.value && <i className="far fa-camera"></i>}
      </div>
    </div>
  );
};

class AccountSettings extends FamilyAccountSettingsForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      piid: null,
      creating: true,
      family_id: null,
      profileFamily: {},
      personalInfo: {},
      activityOptions: [],
    };
  }
  componentDidMount = () => {
    this.getFamilyDetails();
    this.getActivityOptions();
  };
  getFamilyDetails = async () => {
    try {
      const { family_id, id } = this.props.auth.user.personal_info;
      const response = await familyService.get(family_id, id);
      if (response.status === 200) {
        this.formatData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  getActivityOptions = async () => {
    try {
      const response = await httpService.get('/api/activityOptions');
      if (response.status === 200) {
        let { activityOptions } = response.data.data;
        activityOptions = each(activityOptions, (info) => {
          info['value'] = info.id;
          info['label'] = info.name;
        });
        this.setState({ activityOptions });
      }
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({
    pets,
    children,
    profileFamily,
    personalInfo,
    activities,
  }) => {
    const {
      smoke,
      sexual_criminal_offense,
      avatar_filename,
      // secondary_phone,
      secondary_email,
      house_rules,
      sitter_preference,
    } = profileFamily;
    const {
      id,
      family_id,
      firstname,
      lastname,
      address1,
      address2,
      city,
      state,
      zip,
      phone1,
      phone2,
      employer,
      profile_color,
    } = personalInfo;

    const { data } = this.state;

    data['smoke'] = smoke;
    data['sexual_criminal_offense'] = sexual_criminal_offense;
    data['avatar_filename'] = avatar_filename;
    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['address1'] = address1;
    data['address2'] = address2;
    data['city'] = city;
    data['state'] = state;
    data['zip'] = zip;
    data['phone1'] = phone1;
    data['phone2'] = phone2;
    data['employer'] = employer;
    data['profile_color'] =
      profile_color !== null ? profile_color : 'primary-coral';
    // data['secondary_phone'] = secondary_phone;
    data['house_rules'] = house_rules;
    data['sitter_preference'] = sitter_preference;
    data['secondary_email'] = secondary_email;

    const selected_activities = map(activities, (activity) => {
      return activity.id + '';
    });
    data['selected_activities'] = selected_activities;

    this.setState({
      data,
      personalInfo,
      profileFamily,
      family_id,
      piid: id,
    });
  };
  handleProfileColorChange = (colorClass) => {
    const { data } = this.state;
    data['profile_color'] = colorClass;
    this.setState({ data });
  };
  getIsUserPrimary = () => {
    const piid = this.props.auth.user.personal_info.id;
    const ownerPiid = get(this.state, 'profileFamily.owner.id', false);
    return piid === ownerPiid;
  };
  doSubmit = async (callback) => {
    const response = await this.submitFormData();
    callback();
    if (response.status === 200) {
      toast.success('Hooray! Your changes are saved.');
      location.reload();
    }
  };
  conditionallyRenderParent = (parent) => {
    const { id } = this.props.auth.user.personal_info;

    if (id == parent.id) {
      return <div className="fa-row" key={`parent-${parent.id}`} />;
    }

    return (
      <div className="fa-row" key={`parent-${parent.id}`}>
        <div className="fa-col">
          <p>
            {parent.firstname}&nbsp;{parent.lastname}
          </p>
        </div>
        <div className="fa-col">
          <p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.removeUserFromFamily(parent);
              }}
            >
              Remove
            </a>
          </p>
        </div>
      </div>
    );
  };
  removeUserFromFamily = async (parent) => {
    try {
      const response = await familyService.deleteMember(parent.id);
      if (response.status === 200) {
        const { profileFamily } = this.state;
        const { parents } = profileFamily;
        const idx = parents.indexOf(parent);
        if (idx > -1) {
          parents.splice(idx, 1);
          profileFamily['parents'] = parents;
          this.setState({
            profileFamily,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { data, profileFamily, activityOptions } = this.state;
    const { user } = this.props.auth;

    return (
      <main className="account profile settings">
        <form onSubmit={this.handleSubmit}>
          <div
            className={`profile-header ${displayProfileColor(
              data.profile_color,
              'gradient'
            )}`}
          >
            <div className="inner">
              {this.renderFile(
                'avatar_filename',
                'Upload Your Public Profile Image',
                'image/jpg, image/png',
                true,
                AvatarDisplayComponent
              )}
              <h2 className="theme-h2">{data.lastname}&nbsp;Family</h2>
            </div>
          </div>
          <div className="profile-body">
            <h2>Account Settings</h2>
            <ProfileColorPicker
              value={data.profile_color}
              onProfileColorChange={this.handleProfileColorChange}
            />
            <div className="form-group left-align">
              <h3>Family Accounts</h3>
              <div className="family-accounts-table">
                <div className="fa-row">
                  <div className="fa-col">
                    <p>You ({this.props.auth.user.email})</p>
                  </div>
                  <div className="fa-col">
                    <p>
                      <b>{this.getIsUserPrimary() ? 'Primary Account' : ''}</b>
                    </p>
                  </div>
                </div>
                {!!profileFamily.parents &&
                  profileFamily.parents.map((parent) => {
                    return this.conditionallyRenderParent(parent);
                  })}
              </div>
            </div>
            {this.renderForm(true, true, activityOptions)}
            {this.renderButton('Save', 'Saving...', 'theme-btn blue')}
            <div className="cancel-btn">
              <Link className="theme-btn small slate-blue" to="/profile">
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </main>
    );
  }
}

export default connect(mapAuthState)(AccountSettings);
