import React from 'react';
import Form from '../../shared/Form';
import Joi from 'joi';

import httpService from '../../../services/httpService';

class Signup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: null,
        lastname: null,
        email: null,
      },
      errors: {},
      recaptchaAccepted: false,
      formSubmitted: false,
      stepName: 'Family Signup',
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    email: Joi.string().required().label('Email'),
  });
  doSubmit = async (callback) => {
    if (!this.state.recaptchaAccepted) {
      // alert('You must accept the ReCAPTCHA before proceeding.');
      // callback();
      // return;
    }
    try {
      const { data } = this.state;
      const response = await httpService.post('/api/signup/parent', data);
      if (response.status === 200 && response.data.message === 'OK') {
        this.setState({
          formSubmitted: true,
        });
      }
      callback();
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  onChange = () => {
    this.setState({
      recaptchaAccepted: true,
    });
  };
  render() {
    return (
      <div className="registration family step-1 signup">
        <div className="form">
          {!this.state.formSubmitted && (
            <div className="inner">
              <div className="form-header">
                <h2>Sign up for SmartSitter</h2>
                <p>
                  A local online marketplace where families find trusted and
                  responsible Sitters.
                </p>
              </div>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput('firstname', 'First Name', 'text', true)}
                {this.renderInput('lastname', 'Last Name', 'text', true)}
                {this.renderInput('email', 'Email', 'email', true)}
                {this.renderRecaptcha(this.onChange)}
                {this.renderButton('Submit', 'Submitting...', 'theme-btn blue')}
              </form>
            </div>
          )}
          {this.state.formSubmitted && (
            <div className="inner">
              <div className="signup-completed">
                <h2>
                  Almost ready to
                  <br />
                  get started
                </h2>
                <p>
                  We just need to verify your email. Please check your inbox and
                  click the link within.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="image"></div>
      </div>
    );
  }
}

export default Signup;
